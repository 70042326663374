
    import Vue from "vue";
    import Component from "vue-class-component";
    import ApiButton from "@/components/ApiButton.vue";
    import { Route } from "vue-router";
    import { required } from "vuelidate/lib/validators";
    import { Watch } from "vue-property-decorator";
    import { Validations } from "vuelidate-property-decorators";
    import * as toastr from "toastr";

    import store from "@/store/store";
    import apiClient from "@/stuff/ApiClient";
    import { ILookupItem, LookupItem } from "@/model/LookupItem";
    import utils from "@/stuff/Utils";
    import { LookupGroup } from "@/model/Enums";
    import { TriState } from '@/model/Enums';

    @Component({
        components: { ApiButton }
    })
    export default class Lookups extends Vue {

        //
        // -- lifecycle hooks, etc.
        //

        mounted():void {
            this.onGroupChanged(+this.$router.currentRoute.params.groupID);
            console.log("@@@ mounted - groupID = " + this.groupID);
        }

        // if the group ID changes in the URL, the component *will not* reload - so we need to handle it here
        @Watch("$route", { immediate: true, deep: true })
        onRouteChanged(route: Route):void {
            this.onGroupChanged(+route.params.groupID);
            console.log("@@@ Route changed - groupID = " + this.groupID);
        }

        //
        // -- properties
        //

        private groupID = 0;
        private lookupItemList: Array<ILookupItem> = [];
        private readonly lookupItem = new LookupItem();

        private get pageTitle(): string {
            const desc = LookupGroup[this.groupID];
            return desc ? utils.camelCaseAddSpaces(desc) : "Lookups - " + this.groupID;
        }

        private get totalCount(): number {
            return this.lookupItemList.length;
        }

        private get dialogueTitle(): string {
            if (!this.lookupItem) return "- - -";
            if (utils.isEmptyId(this.lookupItem.id)) return "New Item";
            return "Edit " + this.lookupItem.description;
        }

        private get countText(): string {
            return this.totalCount === -1 ? "..." : this.totalCount.toString();
        }

        private get isDeletedSearchOptions(): Array<LookupItem> { return [
            new LookupItem({ id: TriState.UseDefault, description: "All", isArchived: false }),
            new LookupItem({ id: TriState.True, description: "Deleted", isArchived: false }),
            new LookupItem({ id: TriState.False, description: "Not deleted", isArchived: false })
        ];}

        //
        // -- Watchers
        //

        @Watch("searchParameters.isDeleted")
        onIsDeletedChanged(value: number):void {
            console.log("Is deleted changed = ", value);
            this.search();
        }

        //
        // -- methods
        //

        private onGroupChanged(groupID: number) {
            if (this.groupID === groupID) return;
            this.groupID = groupID;
            this.search();
        }

        async edit(lookupItemData: ILookupItem):Promise<void> {
            this.lookupItem.update(lookupItemData); // could go to server but we're not...
            this.$v.$reset();
            this.$bvModal.show("lookupItemDialogue");
        }

        editNew():void {
            utils.resetObject(this.lookupItem);
            this.$v.$reset();
            this.$bvModal.show("lookupItemDialogue");
        }

        cancel():void {
            this.$bvModal.hide("lookupItemDialogue");
            utils.resetObject(this.lookupItem);
        }

        async save(event?: Event):Promise<void> {
            // 'touch' all the fields to activate the validation messages
            this.$v.$touch();
            if (this.$v.lookupItem.$invalid) {
                toastr.info("Please fix the highlighted errors", "Validation errors");
                return;
            }
            await apiClient.post(`/api/lookup/save/${this.groupID}`, this.lookupItem, event);
            toastr.success("Saved");
            this.$bvModal.hide("lookupItemDialogue");
            // redo search - or should we just update in place? - we'd need the ID though.
            this.search();
            this.updateStore();
        }

        // 'delete' is a reserved word
        async deleteItem(event?: Event):Promise<void> {
            if (this.lookupItem.isNew) return;
            const shouldDelete: boolean = await this.$bvModal.msgBoxConfirm("Do you want to delete '" + this.lookupItem.description + "'?", {
                title: "Delete",
                okVariant: "danger",
                okTitle: "Yes, delete!",
                cancelTitle: "No, leave it",
                hideHeaderClose: true,
                centered: true,
                headerClass: "border-bottom-0",
                footerClass: "border-top-0",
                size: "sm"
            });
            if (!shouldDelete) return;
            await apiClient.post(`/api/lookup/delete/${this.groupID}`, this.lookupItem, event);
            toastr.warning("Deleted");
            this.$bvModal.hide("lookupItemDialogue");
            this.search();
            this.updateStore();
        }

        async restore(event?: Event):Promise<void> {
            if (this.lookupItem.isNew) return;
            await apiClient.post(`/api/lookup/restore/${this.groupID}`, this.lookupItem, event);
            toastr.success("Restored");
            this.$bvModal.hide("lookupItemDialogue");
            this.search();
            this.updateStore();
        }

        async search(event?: Event):Promise<void>{
            if (this.groupID < 1) {
                toastr.error("Missing group ID!");
                return;
            }
            // if we want the list to be instances of LookupItem class, we'd need to map here
            this.lookupItemList = await apiClient.get(`/api/lookup/search/${this.groupID}?isArchived=`, event);
        }

        // Can we come up with a better way to do this? ... yeah, don't use the store for lookups!!!
        private updateStore() {
            if (this.groupID === LookupGroup.CompanyType) {
                store.dispatch("loadCompanyTypeList");
            }
            else if (this.groupID === LookupGroup.Country) {
                store.dispatch("loadCountryList");
            }
            else if (this.groupID === LookupGroup.SupplierStatus) {
                store.dispatch("loadSupplierStatusList");
            }
            else if (this.groupID === LookupGroup.CommunicationType) {
                store.dispatch("loadCommunicationTypeList");
            }
            else if (this.groupID === LookupGroup.PaymentType) {
                store.dispatch("loadPaymentTypeList");
            }
            else if (this.groupID === LookupGroup.PaymentMethod) {
                store.dispatch("loadPaymentMethodList");
            }
        }

        // if validations need access to 'this', you'll need to use a function here.
        @Validations()
        validations = {
            lookupItem: {
                description: { required }
            }
        };
    }
